import './Banner.scss';
import Logo from '../../utils/Logo';

const Banner = () => {
    return ( 
        <div className="banner">
            <div className="bannerTitle">
                <Logo />
            </div>
            <div className="bannerFeatures">
               <div className="bannerFeaturesBlock">
                   <div className="feature">
                    <img src={process.env.PUBLIC_URL + "/repeat.png"} alt="powtarzalność" />
                    <p>powtarzalność</p>
                   </div>
               </div>
               <div className="bannerFeaturesBlock">
                   <div className="feature">
                    <img src={process.env.PUBLIC_URL + "/quality.png"} alt="powtarzalność" />
                    <p>jakość</p>
                   </div>
               </div>
               <div className="bannerFeaturesBlock">
                   <div className="feature">
                    <img src={process.env.PUBLIC_URL + "/warehouse.png"} alt="powtarzalność" />
                    <p>szeroki asortyment</p>
                   </div>
               </div>
            </div>
        </div>
     );
}
 
export default Banner;