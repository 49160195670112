import React, {useState} from 'react';
import { useForm } from 'react-hook-form'
import './Form.scss'
import emailjs from 'emailjs-com';
import { Element } from 'react-scroll';

const Form = () => {
const { register, handleSubmit, formState: {errors}} = useForm();

const [formSend, setFormSend] = useState({
    send: false
});


const onSubmit = (e,data) => {
    
    data.target.parentNode.classList.add('sending');
   
    emailjs.sendForm('service_5t8csbk', 'template_cr3k9e7', data.target, 'user_Qm5a9nDNoThYMhoHjl2Sv')
    .then((result) => {
        data.target.parentNode.classList.remove('sending');
        setFormSend({
            send: true
        })
        data.target.reset();
    }, (error) => {
        console.log(error.text);
    });

    
};

const alert = {
    required: 'Pole wymagane',
    email: 'Nieprawidłowy adres email'
}

        return ( 
            <Element id="kontakt" className="sectionPadding">
                <h2 className="titleSection">Skontaktuj się z nami</h2>

                <div className="contactForm">
                    <div className="contact col-md-6">
                        <address className="address" itemScope itemType="http://schema.org/Corporation"> 
                            <img src={process.env.PUBLIC_URL + '/street_icon.png'} alt="phone icon"/>
                            <div>
                                <p>BIOTECH PLAST</p>
                                <p>Słaborowice 16B</p>
                                <p>63-450 Lewków</p>
                            </div>
                        </address>

                        <div className="phone contactLine">
                            <img src={process.env.PUBLIC_URL + '/phone_icon.png'} alt="phone icon"/>
                            <a href="tel:502773756">+48 502 773 756</a>
                        </div>
                        <div className="mail contactLine">
                            <img src={process.env.PUBLIC_URL + '/mail_icon.png'} alt="mail icon"/>
                            <a href="mail:biuro@biotechplast.com">biuro@biotechplast.com</a>
                        </div>
                    </div>
                    <div className="formContact col-md-6" >
                        {formSend.send ? <div className="alert-sucess">Wiadomość została wyslana</div> :
                        <form onSubmit={handleSubmit(onSubmit)}>
                            
                            
                            <div className="inputBlock">
                                <label htmlFor="name">Nazwa firmy / imię nazwisko</label>
                                <input 
                                    id="name"
                                    name="name"
                                    {...register("name",{
                                        required: {
                                            value: true,
                                            message: alert.required
                                        }
                                    })}
                                    placeholder="Wpisz nazwę firmy lub imię nazwisko"
                                    className={`textBlock ${errors.name? 'alert-error' : ''}`}
                                />
                                {errors.name && <span className="error">{errors.name.message}</span>}
                            </div>

                            <div className="inputBlock">
                                <label htmlFor="email">Email</label>
                                <input 
                                    id="email"
                                   
                                    name="email"
                                    {...register("email",{ 
                                        required : {
                                            value: true,
                                            message: alert.required
                                        },
                                        pattern:{
                                            value: /\S+@\S+\.\S+/,
                                            message: alert.email
                                        }
                                })}
                                    placeholder="Wpisz swój adres email"
                                    className={`textBlock ${errors.email? 'alert-error' : ''}`}
                                />
                                <span className="error">{errors.email && errors.email.message }</span>
                                
                            </div>
                            
                            <div className="inputBlock">
                                <label htmlFor="messageText">Treść</label>
                                <textarea 
                                    id="messageText"
                                    name="messageText"
                                    placeholder="Wpisz treść wiadomości"
                                    {...register("messageText",{
                                        required: {
                                            value: true,
                                            message: alert.required
                                        }
                                    })}
                                    className={`textBlock textarea ${errors.messageText? 'alert-error' : ''}`}
                                />
                                <span className="error">{errors.messageText && errors.messageText.message }</span>
                                
                            </div>

                            <div className="inputBlock">
                                <input type='submit' value='Wyślij' className="btn"/>
                            </div>

                        </form>
                        }
                    </div>
                </div>

                <div className="mapGoogle">
                    <p className="gps">Współrzędne: <strong>51.713870, 17.849965</strong></p>
                <iframe className="map" title="BioTechPlast Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8347.963689072143!2d17.84517337236232!3d51.71524725871822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTHCsDQyJzQ5LjkiTiAxN8KwNTAnNTkuOSJF!5e0!3m2!1spl!2spl!4v1628537932251!5m2!1spl!2spl" width="600" height="450" loading="lazy"></iframe>
                </div>
            </Element>
         );
    
}
 
export default Form;