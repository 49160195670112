import './Policy.scss';
import { textPolicy } from './textPolicy';

const Policy = () => {
    return ( 
        <div className="policy">
            <div className="container mx-auto">
                <h1 className="titleSection">Polityka Prywatności</h1>
                   
                   <div dangerouslySetInnerHTML={{ __html: textPolicy}}></div>
            </div>
        </div>
     );
}
 
export default Policy;