import './Offers.scss'
const Offers = () => {
    return ( 
        <div id="oferta" className="sectionPadding">
            <h2 className="titleSection">Oferta</h2>
            <p>W naszej ofercie znajdą Państwo regranulaty i przemiały następujących tworzyw:</p>
            <div className="parameters">
                <ul className="offersParameter col-md-6">
                    <li className="parameter">PP</li>
                    <li className="parameter">PE (HDPE i LDPE)</li>
                    <li className="parameter">PS</li>
                    <li className="parameter">PA</li>
                </ul>
                <ul className="offersParameter col-md-6">
                    <li className="parameter">ABS</li>
                    <li className="parameter">PC</li>
                    <li className="parameter">PC/ABS</li>
                    <li className="parameter">PBT</li>
                </ul>
            </div>

            <div className="gallery">
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/1.png'} alt="gallery" />
                </div>
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/2.png'} alt="gallery" />
                </div>
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/3.png'} alt="gallery" />
                </div>
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/4.png'} alt="gallery" />
                </div>
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/5.png'} alt="gallery" />
                </div>
                <div className="col-md-4 galleryImage">
                    <img src={process.env.PUBLIC_URL + '/img/6.png'} alt="gallery" />
                </div>
            </div>
        </div>
     );
}
 
export default Offers;