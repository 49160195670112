import React, {useState} from "react"
import Navbar from "../../components/Navbar"
import Logo from "../../utils/Logo"
import './Header.scss'


const Header = () => {

    const [navbar,setNavbar] = useState({
        open: false
    })

const handleMobileMenu = (e) => {
    e.preventDefault();
       if(window.innerWidth < 979){
           const htmlElement = document.querySelector('html');
           if(!navbar.open){
                htmlElement.classList.add('open')
                setNavbar({
                    open:true
                })
           }
           else{
            htmlElement.classList.remove('open')
            setNavbar({
                open: false
            })
           }
           
       }
}
    return(
        <header>
            <div id="logo">
                <Logo />
            </div>

            <div className="address">
                <div className="address__icon">
                    <img src={process.env.PUBLIC_URL + '/phone_icon.png'} alt="BioTech Plast telefon"/>
                    <a href="tel:502773756">+48 502 773 756</a>
                </div>
                <div className="address__icon">
                    <img src={process.env.PUBLIC_URL + '/mail_icon.png'} alt="BioTech Plast email"/>
                    <a href="mail:biuro@biotechplast.com">biuro@biotechplast.com</a>
                </div>
            </div>

            <div className='mainMenu'>
                    <Navbar />
                    <div className="mobileMenu" onClick={handleMobileMenu}>
                        <span className="toggleMenu"></span>
                    </div>
                    
            </div>
        </header>
    )
}

export default Header;