import './Footer.scss'
import {Link} from 'react-router-dom'

const Footer = () => {
    const Scroll = require('react-scroll')
    const scroller = Scroll.scroller;
    
   const handleClickScroll = (e) => {
    scroller.scrollTo(e.target.name, {
        smooth: true,
        offset: 50
    })
       
    }
    return ( 
        <>
        <footer>
            <div className="logoFooter">
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt='BioTech PLast Logo' />
            </div>
            <div className="footerMenu">
                <ul className="navbar">
                    <li className="navBlock">
                        <Link className="navLink" to='/' name="ofirmie" onClick={handleClickScroll}>O firmie</Link>
                    </li>
                    <li className="navBlock">
                        <Link className="navLink" to='/' name="oferta" onClick={handleClickScroll}>Oferta</Link>
                    </li>
                    <li className="navBlock">
                        <Link className="navLink" to='/' name="jakosc" onClick={handleClickScroll}>Jakość</Link>
                    </li>
                    <li className="navBlock">
                        <Link className="navLink" to='/' name="kontakt" onClick={handleClickScroll}>Kontakt</Link>
                    </li>
                    <li className="navBlock">
                        <Link className="navLink" to='/polityka-prywatnosci'>Polityka prywatności</Link>
                    </li>
            </ul>
            </div>

            
        </footer>
        <div className="company">
        <a href="https://kodito.pl">
            <img src={process.env.PUBLIC_URL + '/koditoFooter.png'} alt="Kodito"/>
        </a>
    </div>
    </>
     );
}
 
export default Footer;