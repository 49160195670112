import {useState} from 'react';
import './Navbar.scss'
import {Link} from 'react-router-dom'


const Navbar = () =>{
    const Scroll = require('react-scroll')
    const scroller = Scroll.scroller;
    const [offsetCount, setoffsetCount] = useState({
        offset: 50
    });
    
   const handleClickScroll = (e) => {
    const htmlElement = document.querySelector('html');
       if(window.innerWidth < 979){
           htmlElement.classList.remove('open')
           setoffsetCount({
                offset: -5
           })
       }
       
    scroller.scrollTo(e.target.name, {
        smooth: true,
        offset: offsetCount.offset
    })
       
    }
    return(
        <nav>
        <ul className="navbar">
            <li className="navBlock">
                <Link className="navLink" to='/' name="ofirmie" onClick={handleClickScroll}>O firmie</Link>
            </li>
            <li className="navBlock">
                <Link className="navLink" to='/' name="oferta" onClick={handleClickScroll}>Oferta</Link>
            </li>
            <li className="navBlock">
                <Link className="navLink" to='/' name="jakosc" onClick={handleClickScroll}>Jakość</Link>
            </li>
            <li className="navBlock">
                <Link className="navLink" to='/' name="kontakt" onClick={handleClickScroll}>Kontakt</Link>
            </li>
        </ul>
    </nav>
    )
}

export default Navbar;