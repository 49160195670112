import './styles/global.scss'
import './styles/variables.scss'
import Header from './layouts/Header/Header';
import Banner from './components/Banner/Banner';
import DescBlock from './components/DescBlock/DescBlock';
import Offers from './components/Offers/Offers';
import Form from './components/Form/Form';
import Footer from './layouts/Footer/Footer';
import {Switch, Route} from 'react-router-dom';
import Policy from './layouts/Policy/Policy';

function App() {
  return (
    <div className="App">
      
      <Switch>
        <Route path="/" exact>
          
            <Header />
            <Banner />
            <DescBlock name=""/>
            <Offers />
            <DescBlock name="reverse"/>
            <Form />
            <Footer />
         
        </Route>
        <Route path="/polityka-prywatnosci">
          <Header />
          <Policy />
          <Footer />
        </Route>
      </Switch>

      </div>
  );
}


export default App;
