import React,{Component} from 'react';
import './Descblock.scss'
import {Element} from 'react-scroll'

class DescBlock extends Component {

    state = {
        h2Text : 'Kilka słów o naszej firmie',
        paragraph: ['Firma BIO-TECH PLAST powstała w roku 2006 skupiając się początkowo na budownictwie komercyjnym. W 2012 roku poszerzyliśmy naszą działalność o branżę tworzyw sztucznych. Od tego czasu zyskaliśmy już dużą grupę kontrahentów, którzy obdarzyli nas zaufaniem i cenią sobie współpracę z nami. Regularnie dostarczamy naszym klientom wysokiej jakości regranulaty i przemiały tworzyw sztucznych, które pozwalają na znaczne obniżenie kosztów produkcji.','Przykładamy wszelkie starania, aby oferowane przez nas tworzywa spełniały oczekiwania nawet najbardziej wymagających klientów, służymy fachową pomocą  przy wdrażaniu nowych materiałów, zapewniamy także obsługę logistyczną zamówień.',' Gwarantujemy powtarzalne ilości i stałą jakoś naszych produktów. Realizujemy zarówno zamówienia na ilości całopojazdowe, jak i mniejsze dostawy. Stosując odpowiednie metody przetwórstwa jesteśmy w stanie dostarczyć materiały według indywidualnego zapotrzebowania każdego klienta.Zapraszamy do współpracy !'],
        imagePath: '/img/bluematerial.png',
        nameBlock: 'ofirmie'
    }

componentDidMount = () =>{
    if(this.props.name === 'reverse'){
        this.setState({
            h2Text: 'Przede wszystkim stawiamy na jakość!',
            paragraph: ['Dbamy o to, aby ofertowane przez nas materiały spełniały wymagania najwyższej jakości. Korzystamy z usług  jednostek badawczo-naukowych w celu zagwarantowania odpowiednich właściwości dostarczanych przez nas tworzyw (t.j. MFI, udarność, gęstość).','Starannie wybieramy i segregujemy materiały wsadowe. Na jakość naszej oferty wpływ ma również wyszkolona i profesjonalna kadra. '],
            imagePath: '/img/redmaterial.png',
            nameBlock: 'jakosc'
        })
    }
}

render(){
        
    return (  
        <Element id={this.state.nameBlock}>  
        <div className={`descBlock ${this.props.name}`}>
           
           <div className="descBlockGrid">
                <div className="col-md-6 descText">
                    <h2>{this.state.h2Text}</h2>
                    {this.state.paragraph.map((element,index) => {
                        return <p key={index}>{element }</p>
                    })}
                </div>
                <div className="col-md-6 descImage">
                    <img src={process.env.PUBLIC_URL + this.state.imagePath} alt="o firmie BioTech Plast"/>
                </div>
           </div>
        </div>
        </Element>
    );
    }
}
 
export default DescBlock
